import React from 'react'
import styled from 'styled-components'
import Reveal from 'react-reveal/Reveal';
import Fade from 'react-reveal/Fade';

export default function Blog() {
  return (
    <Wrapper>
      <div className="container">
        <div className="blog-circle-bg">
          <img src="/images/blog-circle.svg" alt="" className="circle1"/>
          <img src="/images/blog-circle.svg" alt="" className="circle2"/>
        </div>
        <div className="blog">
          <div className="blog-list">
          <h1>What we share</h1>
            <div className="item">
              <div className="date">12/01</div>
                <a href="/" target="_blank">
                  <h2>Our take on using React with modern application platform</h2>
                  <p>As we speak, our company have been strive to be the best in a company that serve yourr need whenever</p>
                </a>
            </div>
            
            <div className="item">
              <div className="date">12/01</div>
              <a href="/" target="_blank">
                <h2>Our take on using React with modern application platform</h2>
                <p>As we speak, our company have been strive to be the best in a company that serve yourr need whenever</p>
              </a>
            </div>

            <div className="item">
              <div className="date">12/01</div>
              <a href="/" target="_blank">
                <h2>Our take on using React with modern application platform</h2>
                <p>As we speak, our company have been strive to be the best in a company that serve yourr need whenever</p>
              </a>
            </div>
            
            <Reveal effect="moveIn">
              <a href="/" className="button" role="button"><span>Browse our articles</span></a>
            </Reveal>
          </div>
          <Fade bottom>
            <div className="float-image">
              <img src="/images/blog1.png" alt="" className="img1" />
              <img src="/images/blog2.png" alt="" className="img2"/>
              <img src="/images/blog3.png" alt="" className="img3"/>
            </div>
          </Fade>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: #FFFFFF;
  background: linear-gradient(247.8deg, #324B64 0%, #19344F 100%);
  padding: 8rem 0 4rem 0;
  @media screen and (max-width: 767px) {
    overflow: hidden;
  }
  .container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .blog-circle-bg {
      width: 200px;
      height: 250px;
      position: absolute;
      right: -10%;
      bottom: -5rem;
      img {
        max-width: 100%;
        position: absolute;
        max-width: 200px;
      }
      .circle1 {
          margin-top: 20px;
      }
      .circle2 {
        margin-left: 20px;
      }
    }

  @media screen and (min-width: 992px) {
    .blog-circle-bg {
      width: 400px;
      height: 550px;
      position: absolute;
      right: 5%;
      top: 5%;
      img {
        max-width: 100%;
        position: absolute;
        max-width: 500px;
      }
      .circle1 {
          margin-top: 50px;
      }
      .circle2 {
        margin-left: 50px;
      }
    }
  }
  

  @keyframes rotate{
    from{ transform: rotate(-360deg); }
    to{ transform: rotate(360deg); }
  }
  .circle1 {
    animation-name: rotate;
    animation-duration: 9s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .circle2 {
    animation-name: rotate;
    animation-duration: 18s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
  }

  h1 {
    font-size: 4rem;
    margin-bottom: 4rem;
  }

  a.button.moveIn {
    span {
      margin-left: 0px;
    }
  }
  a.button {
    color: #FFFFFF;
    display: inline-block;
    line-height: 66px;
    z-index: 999;
    padding-left: 36px;
    margin-top: 2rem;
    margin-bottom: 5rem;
    /* margin-left: -35px; */
    
    span {
      transition: 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      margin-left: 40px;
    }
    &::before {
      transition: 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53);
      height: 60px;
      width: 60px;
      content: "";
      border: 2px solid rgba(255,255,255, 0.2);
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
    }
  }
  a.button:hover {
    span {
      margin-left: 40px;
    }
    &::before {
      border: 2px solid rgba(255,255,255, 1);
    }
  }
  .blog {
    @media screen and (min-width: 992px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
    }
  }
  .blog-list {
    @media screen and (min-width: 992px) {
      min-height: 700px;
    }
    .item {
      padding-bottom: 3rem;
      @media screen and (min-width: 992px) {
        display: grid;
        grid-template-columns: 70px auto;
      }
      border-bottom: 1px solid #B6B6B6;
      margin-bottom: 3rem;
    }
    a {
      display: inline-block;
      color: #FFFFFF;
    }
    .date {
      margin-bottom: 12px;
      margin-top: 5px;
    }
    h2 {
      font-size: 2rem;
      font-weight: 600;
      line-height: 140%;
      margin-bottom: 12px;
    }
    p {
      line-height: 140%;
    }
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  .img1 {
    transform: translatey(0px);
	  animation: float 6s ease-in-out infinite;
  }
  .img2 {
    transform: translatey(5px);
	  animation: float 4s ease-in-out infinite;
  }
  .img3 {
    transform: translatey(8px);
	  animation: float 5s ease-in-out infinite;
  }

  .float-image {
    margin-top: -120px;
    display: none;
    @media screen and (min-width: 992px) {
      display: grid;
      grid-template-areas:
      'img1 img3'
      'img2 img3';
      grid-gap: 30px;
    }
    .img1 {
      max-width: 100%;
      grid-area: img1;

    }
    .img2 {
      max-width: 100%;
      grid-area: img2;
      margin-top: -50px;
    }
    .img3 {
      margin-top: 120px;
      max-width: 100%;
      grid-area: img3;
    }
  }
  
`