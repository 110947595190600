import React from 'react'
import styled from 'styled-components'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Reveal from 'react-reveal/Reveal';
import Marquee from "react-fast-marquee";

export default function Mission({setIsOpeningPosition}) {
  return (
    <Wrapper>
      <span className="circle-group">
        <img src="/images/circle-mission.svg" alt="" className="circle1"/>
        <img src="/images/circle-mission.svg" alt="" className="circle2"/>
      </span>
      <div className="container" id="about-us">
        <p>Our mission is productivity & efficiency</p>
        <h1>Pi R Square is a Tech Company focusing on increasing <br/>
            productivity to our clients. We define a new standard of <br/>
            working in the workplace, by empathizing their working <br/> 
            behavior. Our team’s strength is harmonizing client’s <br/>
            needs with Design and Tech. User Experience will be <br />
            the key to benefit your business.
        </h1>
        <br />

        <AnchorLink to='/#what-we-do' className="button">
          <Reveal effect="moveIn">
            <span>Find out more about our products:</span>
          </Reveal>
        </AnchorLink>

      </div>
      <div className="pi-number">
        <Marquee gradient={false}>
          <span>3.1415 9265 3589 7932 3846 2643 3832 7950 2884 1971 6939 9375 1058 2097 4944 5923 0781 6406 2862 0899 8628 0348 2534 2117 0679 8214 8086 5132 8230 6647 0938 4460 9550 5822 3172 5359 4081 2848 1117 4502 8410 2701 9385 2110 5559 6446 2294 8954 9303 8196 4428 8109 7566 5933 4461 2847 5648 2337 8678 3165 2712 0190 9145 6485 6692 3460 3486 1045 4326 6482 1339 3607 2602 4914 1273 7245 8700 6606 3155 8817 4881 5209 2096 2829 2540 9171 5364 3678 9259 0360 0113 3053 0548 8204 6652 1384 </span>
          </Marquee>
      </div>
      <div className="connect-section">
        Math is the fundamental of art and tech<br/>Pi is the infinite value.
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  .container {
    margin: 0 auto;
    padding: 9rem 5rem 9rem 5rem;
    @media screen and (min-width: 768px) {
      max-width: 600px;
    }
    @media screen and (min-width: 998px) {
      padding: 9rem 5rem 20rem 5rem;
      max-width: 990px;
    }
    @media screen and (min-width: 1200px) {
      max-width: 1000px;
    }
    
  }
  @media screen and (max-width: 767px) {
      br {
        display: none;
      }
  }
  /* height: 625px; */
  background: linear-gradient(104.79deg, #C5283F 0%, #93071B 100%);
  p {
    margin-bottom: 10px;
    color:#FFFFFF;
  }
  h1 {
    font-size: 2rem;
    @media screen and (min-width: 998px) {
      font-size: 3.4rem;
    }
    color: #FFFFFF;
    line-height: 130%;
  }

  button.button.moveIn {
    span {
      margin-left: 0px;
    }
  }
  .button {
    color: #FFFFFF;
    display: inline-block;
    line-height: 66px;
    z-index: 999;
    padding-left: 36px;
    margin-top: 2rem;
    margin-bottom: 5rem;
    cursor: pointer;
    /* margin-left: -35px; */
    
    span {
      border: none;
      transition: 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      margin-left: 40px;
    }
    span.moveIn {
      margin-left: 0px;
    }
    &::before {
      transition: 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53);
      height: 60px;
      width: 60px;
      content: "";
      border: 2px solid rgba(255,255,255, 0.2);
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
    }
  }
  .button:hover {
    span {
      margin-left: 40px;
    }
    &::before {
      border: 2px solid rgba(255,255,255, 1);
    }
  }

  .pi-number {
    position: absolute;
    bottom: 0;
    right: -300px; 
    margin-bottom: 13rem;
    font-size: 4rem;
    color: #FFFFFF;
    opacity: 0.2;
    border-left: 3px solid #FFFFFF;
    display: none;
    overflow: hidden;
    white-space: nowrap;
    max-width: 80%;
    position: absolute;
    @media screen and (min-width: 998px) {
      display: inline-block;
    }
    .marquee-container {
      overflow-y: hidden;
    }
  }


  .circle-group {
    position: absolute;
    top: 0;
    left: -20%;
    width: 200px;
    @media screen and (min-width: 768px) {
       top: 0;
      left: -25%;
      width: 300px;
    }
    @media screen and (min-width: 998px) {
      top: 0;
      left: -30%;
      width: 400px;
    }
    @media screen and (min-width: 1200px) {
      top: 0;
      left: -20%;
      width: 500px;
    }
    
    img {
      position: absolute;
    }

    @keyframes rotate{
      from{ transform: rotate(-360deg); }
      to{ transform: rotate(360deg); }
    }
    
    .circle1 {
      margin-top: -50px;
      width: 100%;
      animation-name: rotate;
      animation-duration: 16s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .circle2 {
      rotate: 180deg;
      width: 100%;
      animation-name: rotate;
      animation-duration: 8s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  .connect-section {
    line-height: 160%;
    color:#FFFFFF;
    font-size: 12px;
    font-weight:500;
    border-left: 3px solid #FFA3B0;
    position: absolute;
    bottom: 0; 
    z-index: 9990;
    padding-left:20px;
    padding-top: 0px;
    padding-bottom: 15px;
    left: 20%;
    z-index: 8000;
    br {
      display: block !important;
    }
    @media screen and (min-width: 992px) {
      left: 30%;
      font-size: 16px;
    }
  }
`
