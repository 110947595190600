import React from 'react'
import styled from 'styled-components'
import Marquee from "react-fast-marquee";



export default function Hero() {

  return (
    <Wrapper id="top">
      <div className="key-message">
        <div class="message">
          <h1>We believe in <span className="blue">strengthen</span><br />TECHnology with the<br /> power of <span className="red">DESIGN.</span> </h1>
          <p>at Pi R Square, user behavior is the core value we care<br /> to ensure our solutions make them a VIP.</p>
        </div>
        <div className="tagline">We draw circle by formula</div>
        <div className="float-group">
          <div className="social">
            <a href="https://www.facebook.com/pir2thailand/" target="_blank" rel="noreferrer">
              <img src="/images/facebook.svg" alt="Pi R SQUARE Facebook" />
            </a>
          </div>
          <div className="pi-number">
              <Marquee gradient={false}>
              <span>3.1415 9265 3589 7932 3846 2643 3832 7950 2884 1971 6939 9375 1058 2097 4944 5923 0781 6406 2862 0899 8628 0348 2534 2117 0679 8214 8086 5132 8230 6647 0938 4460 9550 5822 3172 5359 4081 2848 1117 4502 8410 2701 9385 2110 5559 6446 2294 8954 9303 8196 4428 8109 7566 5933 4461 2847 5648 2337 8678 3165 2712 0190 9145 6485 6692 3460 3486 1045 4326 6482 1339 3607 2602 4914 1273 7245 8700 6606 3155 8817 4881 5209 2096 2829 2540 9171 5364 3678 9259 0360 0113 3053 0548 8204 6652 1384 </span>
              </Marquee>
          </div>
        </div>
        <div className="h-circle-group">
          <div className="blue-ball"></div>
          <img src="/images/h-circle1.svg" alt="" className="h-circle1"/>
          <img src="/images/h-circle2.svg" alt="" className="h-circle2"/>
        </div>
      </div>

      <div className="circle-show">
        <div className="circle-group">
          <img src="/images/circle.svg" alt="" className="circle1"/>
          <img src="/images/circle.svg" alt="" className="circle2" /> 
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .circle-show {
    display: none;
  }
  .key-message {
    padding: 20px;
    padding-top: 150px;
    padding-bottom: 50px;
    background-color: #FFFFFF;
    overflow: hidden;
    
    @media (min-width: 768px) and (max-width: 1199px) {
      padding-top: 260px;
      padding-bottom: 100px;
    }
     @media screen and (max-width: 1319px) {
      padding: 20px;
      padding-top: 180px;
      padding-bottom: 300px;
      padding-left: 30px;
    }
    .h-circle-group {
      @media screen and (min-width: 1199px) {
        display: none !important;
      }
      
      .blue-ball {
        position: absolute;
        height: 64px;
        width: 64px;
        background-color: red;
        border-radius: 50%;
        background: radial-gradient(78.75% 91.25% at 21.25% 8.75%, #DDEEFF 0%, #79A9DA 33.85%, #3A628A 74.05%, #324B64 96.01%);
        box-shadow: 0px 20px 10px rgba(50, 75, 100, 0.1);
        margin-left: 70px;
        margin-top: 160px;
        z-index: 8000;
        transform: translatey(0px);
	      animation: float 6s ease-in-out infinite;
      }
      position: absolute;
      bottom: 50px;
      right: -80px;
      width: 300px;
      height: 300px;
      img {
        position: absolute;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: spincircle;
        opacity: 0.4;
      }
      @keyframes spincircle{
        from{ transform: rotate(-360deg); }
        to{ transform: rotate(360deg); }
      }

      @keyframes float {
        0% {
          box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
          transform: translatey(0px);
        }
        50% {
          box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
          transform: translatey(-20px);
        }
        100% {
          box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
          transform: translatey(0px);
        }
      }


      img.h-circle1 {
        width: 80%;
        right: -20%;
        animation-duration: 3s;
      }
      img.h-circle2 {
        width: 100%;
        right: -20%;
        animation-duration: 6s;
      }
    }
  }


  .tagline {
    display: none;
  }
  
  .message {
    margin-top: -40px;
  }
  .message {
    p br {
      display: none;
      @media screen and (min-width: 768px) {
        display: block;
      }
    }
  }
  .message p {
    color:#2F2F2F;
    margin-top: 3rem;
    line-height: 140%;
  }
  .message h1 {
    color: #2F2F2F;
    font-size: 3rem;
    line-height: 130%;
    @media (min-width: 768px) {
      font-size: 4rem;
    }
    @media (min-width: 1400px) {
      font-size: 5rem;
    }
    font-weight: 600;
    span.blue {
      color: #87CCE6;
      text-decoration: underline;
    }
    span.red{
      color: #C5283F;
      text-decoration: underline;
    }
  }
  .tagline {
    color:#CECECE;
  }

  .float-group {
  
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    .pi-number {
      border-left: 2px solid #111111;
      font-size: 36px;
      color: #111111; 
      opacity: 0.1;
      margin-right: 40px;
      margin-top: 6px;
      overflow: hidden;
      white-space: nowrap;
      max-width: 50%;
      @media (max-width: 767px) {
        max-width: 70%;
      } 
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .marquee-container {
      overflow-y: hidden;
    }
  }

  @media screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    .key-message {
      padding-top: 100px;
      height: 700px;
      display: grid;
      align-items: center;
      justify-content: center;
    }
    .message {
      padding-left: 0px;
      padding-bottom: 0;
    }
    .tagline {
      position: absolute;
      transform: rotate(-90deg);
      left: 0;
      top: 45% !important;
      margin-left: -50px;
      display: inline-block;
    }

    .circle-show {
      padding: 20px;
      background: url('/images/head.jpg');
      background-size: cover;
      display: grid;
      .circle-group {
      margin: 0 20%;
      display: grid;
      align-items: center;
      justify-content: center;
      position: relative;

      animation-name: rotate;
      animation-duration: 30s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      
      img {
        position: absolute;
      }
      @keyframes rotate{
        from{ transform: rotate(-360deg); }
        to{ transform: rotate(360deg); }
      }
      .circle1, .circle2 {
          animation-name: rotate;
          animation-duration: 15s;
          animation-iteration-count: infinite;
          animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
        }
        .circle1 {
          top: 10%;
          left: 10%;
        }
        .circle2 {
          bottom: 10%;
          left: 10%;
          rotate: 180deg;
        }
      }

    }

  }
`