import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade';

export default function Client() {
  return (
    <Wrapper>
      <div className="container" id="clients">
        <div className="title-group">
          <h1>TRUSTED <span>BY LEADING COMPANIES</span></h1>
          <p>We thought, empathize, discovered and designed great solutions to all of our clients for a decade</p>
        </div>
        <div className="client-group mobile">
            <Fade left>
            <div className="image">
              <img src="/images/clients/11.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/12.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/13.png" alt="" />
            </div>
            </Fade>
          <Fade right>
            <div className="image">
              <img src="/images/clients/14.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/15.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/16.png" alt="" />
            </div>
            </Fade>
          <Fade left>
            <div className="image">
              <img src="/images/clients/17.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/21.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/22.png" alt="" />
            </div>
            </Fade>
          <Fade right>
            <div className="image">
              <img src="/images/clients/23.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/24.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/25.png" alt="" />
            </div>
            </Fade>
          <Fade left>
            <div className="image">
              <img src="/images/clients/26.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/27.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/31.png" alt="" />
            </div>
            </Fade>
          <Fade right>
            <div className="image">
              <img src="/images/clients/32.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/33.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/34.png" alt="" />
            </div>
            </Fade>
          <Fade left>
            <div className="image">
              <img src="/images/clients/35.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/36.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/37.png" alt="" />
            </div>
          </Fade>
          <Fade right>
            <div className="image">
              <img src="/images/clients/41.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/42.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/43.png" alt="" />
            </div>
          </Fade>
          <Fade left>
            <div className="image">
              <img src="/images/clients/44.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/45.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/46.png" alt="" />
            </div>
            </Fade>
          <Fade right>
            <div className="image">
              <img src="/images/clients/47.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/51.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/52.png" alt="" />
            </div>
          </Fade>

        </div>
        <div className="client-group desktop"> 
          <div className="line_1">
            <Fade left>
            <div className="image">
              <img src="/images/clients/11.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/12.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/13.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/14.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/15.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/16.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/17.png" alt="" />
            </div>
              </Fade>
          </div>

          <div className="line_2">
            <Fade right>
            <div className="image">
              <img src="/images/clients/21.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/22.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/23.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/24.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/25.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/26.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/27.png" alt="" />
            </div>
              </Fade>
          </div>

          <div className="line_3">
            <Fade left>
            <div className="image">
              <img src="/images/clients/31.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/32.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/33.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/34.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/35.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/36.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/37.png" alt="" />
              </div>
              </Fade>
          </div>

          <div className="line_4">
            <Fade right>
            <div className="image">
              <img src="/images/clients/41.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/42.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/43.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/44.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/45.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/46.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/47.png" alt="" />
              </div>
              </Fade>
          </div>

          <div className="line_5">
            <Fade left>
            <div className="image">
              <img src="/images/clients/51.png" alt="" />
            </div>
            <div className="image">
              <img src="/images/clients/52.png" alt="" />
              </div>
              </Fade>
          </div>
        </div>



      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 4rem 0;
  @media screen and (min-width: 768px) {
    padding: 8rem 0;
  }
  background-color: #FFFFFF;
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
  .title-group {
    text-align: center;
    p {
      line-height: 140%;
      color: #2F2F2F;
    }
  }
  
  h1 {
    text-align: center;
    color: #324A64;
    font-size: 3rem;
    line-height: 140%;
    font-weight: 600;
    @media screen and (min-width: 768px) {
      font-size: 3.5rem;
      br {
        display: none;
      }
    }
    @media screen and (min-width: 992px) {
      font-size: 4rem;
    }
    span {
      color: #C53A3F;
    }
  }
  .client-group {
    padding: 5rem 0;
  }
  @media screen and (max-width: 767px) {
    .client-group.mobile {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding-bottom: 0;

    }
    .client-group.desktop { display: none; }
  }

  @media screen and (max-width: 320px) {
    .client-group.mobile {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 0;

    }
    .client-group.desktop { display: none; }
  }

  @media screen and (min-width: 768px) {
    .client-group.mobile { display: none; }
    .client-group.desktop { display: block; }
  }
  .line_1,
  .line_2,
  .line_3,
  .line_4,
  .line_5 {
    text-align: center;
    @media screen and (max-width: 767px) {
      img {
        max-width: 100%;
      }
      /* display: grid;
      grid-template-columns: repeat(4, 1fr); */
    }
    @media screen and (min-width: 768px) {
    border-bottom: 1px solid #C5283F;
    /* grid-template-columns: repeat(3, 1fr); */
    padding: 2rem 0;
    }
    .image {
      display: inline-block;
      @media screen and (max-width: 991px) {
        margin-bottom: 30px;
      }
    }
    @media screen and (min-width: 768px) {
      /* grid-template-columns: repeat(5, 1fr); */
      padding: 4rem 0;
    }
  }
  .line_5 {
    border-bottom: none;
  }

  .image {
    text-align: center;
  }
  img {
    max-width: 100%;
    transition: 0.4s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    margin-bottom: 50px;
    
    @media screen and (min-width: 768px) {
      max-height: 40px;
      margin-bottom: 0px;
    }
  }
  img:hover {
    transform: translateY(-10px);
  }
`