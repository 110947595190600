import React, { useState } from 'react'
import '../styles/app.scss'
import { GlobalStyle } from '../styles/GlobalStyle'
import Header from "../components/common/Header"
import Footer from "../components/common/Footer"
import TalkToUs from "../components/home/TalkToUs"
import OpenPosition from "../components/home/OpenPosition"
import { AppState } from "../data/State"

export default function Home({ children }) { 
  
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isTaskToUs, setIsTaskToUs] = useState(false)
  const [isOpeningPosition, setIsOpeningPosition] = useState(false)

  return (
    <> 
      <GlobalStyle />
      <Header
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        setIsTaskToUs={setIsTaskToUs}
        setIsOpeningPosition={setIsOpeningPosition}
      />

      <AppState.Provider value={{isOpeningPosition, setIsOpeningPosition}}>
        <main class="homepage">{children}</main>
        <Footer setIsOpeningPosition={setIsOpeningPosition} />
      </AppState.Provider>
      
      
      <TalkToUs
        isTaskToUs={isTaskToUs}
        setIsTaskToUs={setIsTaskToUs}
      />
      <OpenPosition
        isOpeningPosition={isOpeningPosition}
        setIsOpeningPosition={setIsOpeningPosition}
      />
    </>
    
  )
}