import React from 'react'
import styled from 'styled-components'
import Reveal from 'react-reveal/Reveal';
import { Link } from 'gatsby';

export default function SolutionListed({ setIsTaskToUs }) {
  return (
    <Wrapper id="what-we-do">
      <div className="connect-section"></div>
      <div className="container" >

        <div className="floww-product e-ordering">
          <div className="img">
            <h1><span>Our</span> Solutions</h1>
            <p className="brand">Floww</p>
            <h2>e-Ordering</h2>
            <p>is a solution for Wholesale business or B2B who trouble with managing their order and inventory. This product is the new standard to ensure your admin user can become faster person in a short period of time. Lower your cost and increase your sale is our key designing this solution. Compatible with mobile and desktop devices. We know working anywhere and shorten their time is the key to win in business nowadays.</p>
            <Link to="/our-solutions/e-ordering" className="button">
              <Reveal effect="moveIn">
                <span>Discover more about floww</span>
              </Reveal>
            </Link>
          </div>
          <div className="text">
            <div className="image">
              <Reveal effect="scaleIn">
                <span className="circle-bg"></span>
              </Reveal>
              <img src="/images/e-ordering.png" alt="" />
            </div>
          </div>
        </div>

        <div className="floww-product after-sale">
          <div className="img">
            <p className="brand">Floww</p>
            <h2>After Sale Service</h2>
            <p>Working on data about product detail, product warranty and tasks management on excel is a painful. This is the solution to solve this problem, and every staffs can working together on cloud. No more lost or forgotten their assignedtasks or spending budget on unnecessary. This solution can save you a lot of time and cost to manage your workforce.</p>
            
            <Link to="/our-solutions/after-sale" className="button">
              <Reveal effect="moveIn">
                <span>Discover more about floww</span>
                </Reveal>
            </Link>
          </div>
          
          <div className="text">
              <div className="image">
              <Reveal effect="scaleIn">
                <span className="circle-bg"></span>
              </Reveal>
              <img src="/images/after-sale.png" alt="" />
              </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #F9F9F9;
  padding-top: 10rem;
  padding-bottom: 10rem;
  
  .container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px;
  }
  .floww-product {
      @media screen and (min-width: 998px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
      }
      .img {
        grid-area: img;
      }
      .text {
        grid-area: text;
      }
  }
  .floww-product.e-ordering {
    margin-bottom: 10rem;
  }
  @media screen and (min-width: 998px) {
    .floww-product.e-ordering {
      grid-template-areas: 'text img ';
    }

    .floww-product.after-sale {
      .brand {
        margin-top: 10rem;
      }
      grid-template-areas: 'img text ';
    }
  }


  h1 {
    font-size: 4rem;
    font-weight: 600;
    color: #C5283F;
    margin-bottom: 3rem; 
    @media screen and (min-width: 768px) {
      font-size: 5rem;
      margin-bottom: 8rem;   
    }
    span {
      color: #87CCE6;
    }
  }

  p.brand {
    font-weight: 600;
    margin-bottom: 4px;
    color: #87CCE6;
  }
  h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: 600;
    @media screen and (min-width: 768px) {
      font-size: 4rem;
      margin-bottom: 3rem;
    }
  }
  p {
    color: #111111;
    line-height: 140%;
  }

  
  
  .button {
    color: #111111;
    display: inline-block;
    line-height: 66px;
    z-index: 999;
    padding-left: 36px;
    margin-top: 2rem;
    margin-bottom: 5rem;
    cursor: pointer;
    /* margin-left: -35px; */
    
    span {
      border: none;
      transition: 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      margin-left: 40px;
    }
    span.moveIn {
      margin-left: 0px;
    }
    &::before {
      transition: 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53);
      height: 60px;
      width: 60px;
      content: "";
      border: 2px solid rgba(17,17,17, 0.2);
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
    }
  }
  .button:hover {
    span {
      margin-left: 40px;
    }
    &::before {
      border: 2px solid rgba(17,17,17, 1);
    }
  }
  
  .e-ordering {
    span.circle-bg {
      background-color: #324A64;
    }
  }

  .after-sale {
    span.circle-bg {
      background-color: #334B48;
    }
  }

  .image {
    position: relative;
    text-align: center;
    span.circle-bg {
      border-radius: 50%;
      top: 7rem;
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto; 

      height: 30rem;
      width: 30rem;
      display: inline-block;
      position: absolute;
      z-index: -0;
      transform: scale(0.1);
      transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      
      &.scaleIn {
        transform: scale(1);
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
      }
    }
    img {
      max-width: 25rem;
    }
  }
  .connect-section {
    color:#FFFFFF;
    font-size: 16px;
    font-weight:500;
    border-left: 3px solid #FFA3B0;
    position: absolute;
    top: 0;
    padding-left:30px;
    padding-top: 30px;
    padding-bottom: 20px;
    left: 20%;
    @media screen and (min-width: 992px) {
      left: 30%;
    }
  }

`
 