import React from "react"
import Home from "../layouts/home"
import SiteSeo from "../components/common/SiteSeo"
import Hero from "../components/home/Hero"
import Mission from "../components/home/Mission"
import Solution from "../components/home/Solution"
import Floww from "../components/home/Floww"
import Client from "../components/home/Client"
import Blog from "../components/home/Blog"
import WorkWithUs from "../components/home/WorkWithUs"


export default function IndexPage({setIsTaskToUs, setIsOpeningPosition}) {
  return (
    <Home>
      <SiteSeo title="Pi R SQUARE Limited | We Draw a Circle by Formula."/>
      <Hero />
      <Mission />
      <Solution setIsTaskToUs={setIsTaskToUs}/>
      <Floww />
      <Client />
      {/* <Blog /> */}
      <WorkWithUs />
    </Home>
  )
}
