import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppState } from '../../data/State'

export default function WorkWithUs() {
  const state = useContext(AppState)
  const {setIsOpeningPosition} = state
  return (
    <Wrapper>
      <div className="container">
        <div className="image">
          <img src="/images/pir-full-logo.png" alt="" />
        </div>
        <div className="text">
          <div>
            <h1>Work with us</h1> 
            <p><strong>We are looking for more teammates!</strong></p>
            <p>Here at Pi R Square, we believe in sport team culture. <br />Win together and win the same mission is our goal.</p>
            <br />
            <p>We are building team’s environment to make sure our <br />path will be last long and enjoyable. If you believe the <br />same and want to transform people with technology,</p>
            
            <p><strong>talk to us!</strong></p>
            
            <button onClick={() => setIsOpeningPosition(true)}>Discover our Opening Positions</button>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  /* padding: 15rem 0 10rem 0; */
  background-image: url('/images/footer.jpg');
  background-position: center center;
  background-size: cover;
  color: #111111;
  line-height: 140%;
  padding: 4rem 0;
  .container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px;
    display: grid;
    grid-gap: 30px;
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: right;
      grid-template-columns: 1fr 1fr;
    }
  }
  .text {
    display: grid;
    align-items: center;
    padding: 4rem 0;
    @media screen and (max-width: 767px) {
      padding: 5rem 0;
    }
  }

  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    .image {
      height: 38vw;
    }
    img {
      position: absolute;
      top: 0%;
      left: -20%;
    }
  }


  h1 {
    font-size: 4rem;
    margin-bottom: 16px;
    font-weight: 600;
  }
  button {
    cursor: pointer;
    background: none;
    display: inline-block;
    margin-top: 4rem;
    font-weight: 600;
    color: #111111;
    text-decoration: underline;
  }
  p {
    margin-bottom: 5px;
  }
  strong {
    font-weight: 500;
    font-size: 1.1em;
  }
  br {
    display: none;
      @media screen and (min-width: 768px) {
      display: block;
    }
  }
  
`